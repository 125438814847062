import * as React from "react"
import HeaderMenu from "../components/header/menu";
import FooterWrapper from "../components/footer/wrapper";
import {FormProvider, useForm} from "react-hook-form";
import InputSelect from "../components/forms/input-select";
import BtnNextRed from "../components/buttons/btn-next-red";
import axios from "axios";
import InputText from "../components/forms/input-text";
import {useState} from "react";
import {has} from "lodash";

const ThankYou = ({location}) => {

    const params = new URLSearchParams(location.search);
    const action = location.pathname+location.search;
    console.log(location);
    const hash = params.get("hash");
    const question = params.get("question");

    let questions = [];

    if (question !== "W trakcie budowy") {
        {/*Rodzaj instalacji ? (Podłogowa/grzejnikowa/mieszana)*/
        }
        {/*Jaki jest kocioł ? Tylko budynek istniejący*/
        }

        questions = [{
            id: 'typeOfInstallation',
            name: 'Jaki jest rodzaj instalcji?',
            label: 'Jaki jest rodzaj instalcji?',
            required: true,
            options: ["Podłogowa", "Grzejnikowa", "Mieszana"]
        }, {
            id: 'typeOfBoiler',
            name: 'Jaki jest rodzaj kotła?',
            label: 'Jaki jest rodzaj kotła?',
            required: true,
            options: ["Na drewno i węgiel", "Na pellet", "na Gaz", "Inny"]
        }];

    }

    const onSubmit = async (formData) => {
        try {
            await axios
                .put(process.env.API_URL + "/leads/update", {
                    hash:hash,
                    questions: formData
                })
                .catch()
                .finally(() => console.log('..REQEST FINISHED.'));
        }catch (err) {

        }

    };
    const methods = useForm();

    const [count, setCount] = useState("Tak");
    let page = {
        header: {
            title: "Podziękowania za wypełnienie formularza",
            paragraph: "Odpowiedz jeszcze na kilka pytań",
        },
        paragraphs: [],
        footer: []
    };

    return (
        <main>
            <title>Podziękowania</title>
            <HeaderMenu title={page.header.title} info={page.header.phone}/>
            <section className='pt-16 pb-12 sm:pb-48 md:pb-40 bg-white'>
                <div className='container text-center'>
                    <header className='mb-6 suffix__bottom-blue--center'>
                        <h2 className='title__h2 pb-5'>{page.header.title}</h2>
                        <p className='title__prefix mb-5'>{page.header.paragraph}</p>

                    </header>
                    <FormProvider {...methods} >

                        {/*Powierzchnia budynku ?*/}
                        {/*Czy budynek nowy ? Tak/nie*/}
                        {/*Rok budowy ?*/}


                        <form action={action} method="GET" onSubmit={methods.handleSubmit(onSubmit)}>
                            <InputText
                                id="buildingArea"
                                name="Jaka jest powierzchnia budynku?"
                                label="Jaka jest powierzchnia budynku?"
                                required={true}
                            ></InputText>
                            <InputText
                                id="buildingYear"
                                name="Rok budowy budynku lub rozpoczęcia budowy?"
                                label="Rok budowy budynku lub rozpoczęcia budowy?"
                                required={true}
                            ></InputText>

                            {questions.map((e, i) => {
                                return (
                                    <InputSelect
                                        key={e.id}
                                        id={e.id}
                                        name={e.name}
                                        label={e.label}
                                        required={e.required}
                                        options={e.options}
                                    ></InputSelect>
                                );
                            })}

                            <div className="w-max mx-auto">
                                <div className='text-center sm:text-left'>
                                    <BtnNextRed label="Wyślij"></BtnNextRed>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </section>

            <FooterWrapper pages={page.footer}></FooterWrapper>
        </main>
    )
}

export default ThankYou
